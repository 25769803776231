@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  margin: 0 0 pxToRem(40);
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: pxToRem(20) pxToRem(16);
}

.link {
  text-decoration: none;
  color: inherit;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

@include sm {
  .container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: pxToRem(24);
  }
}

@include md {
  .container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: pxToRem(24);
  }
}

@include mdp {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}
