@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  + .root {
    padding-top: 0;
  }

  + :global(.CustomerReviewsScore) {
    padding-top: 0;
    margin-top: pxToRem(-24);
  }
}

.header {
  padding: 0 0 pxToRem(32);
  text-align: center;
}

.container {
  --horizontal-padding: 0;
  display: grid;
  padding: 0 var(--horizontal-padding);
  max-width: pxToRem(832);
  box-sizing: content-box;
  margin: auto;
  color: var(--tex-def-tie1);

  .subTitle {
    margin: pxToRem(8) 0 0;
  }

  .content {
    margin: pxToRem(16) 0;
  }
}

.content {
  p {
    margin: 0 0 pxToRem(20);
    font-weight: 400;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.content,
.buttonContainer {
  &:last-child {
    margin-bottom: pxToRem(32);
  }
}

.imgWrapper,
.videoWrapper {
  max-width: 100%;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;
}

.videoWrapper {
  aspect-ratio: 1/1;
}

.image,
.video {
  width: 100%;
  display: block;
}

.image {
  height: auto;
  object-fit: contain;
}

.video {
  height: 100%;
  object-fit: cover;
}

@include sm {
  .container {
    --horizontal-padding: #{pxToRem(12)};
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(20);

    &.reverse {
      direction: rtl;

      .contentWrapper {
        direction: ltr;
      }
    }
  }

  .contentWrapper {
    align-self: center;
  }
}

@include smp {
  .container {
    grid-template-columns: 1fr pxToRem(342);
  }

  .content,
  .buttonContainer {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include md {
  .header {
    padding-bottom: pxToRem(48);
  }

  .container {
    column-gap: pxToRem(88);
  }
}

@include lg {
  .header {
    padding-bottom: pxToRem(56);
  }

  .container {
    max-width: pxToRem(1064);
    grid-template-columns: 1fr pxToRem(414);
    column-gap: pxToRem(128);
  }

  .content {
    @include fontSize(17, 24);
    margin-bottom: pxToRem(24);
  }
}

@include xl {
  .header {
    padding-bottom: pxToRem(80);
  }

  .container {
    grid-template-columns: 1fr pxToRem(520);
    column-gap: pxToRem(156);
  }

  .content {
    @include fontSize(20, 28);
    margin-bottom: pxToRem(28);
  }
}
