@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.container {
  padding: pxToRem(24);
  background: var(--for-sec-buf_blu);
  max-width: pxToRem(1280);
  margin: auto;
  border-radius: var(--cor_rad-lg);
}

.title {
  margin-bottom: pxToRem(8);
}

.markdown {
  color: var(--tex-def-tie1);

  a {
    font-weight: 800;
    color: $color-uiPrimary-600;
  }
}

.imgContainer {
  margin-top: pxToRem(48);
  display: flex;
  gap: pxToRem(24);
}

.image {
  width: pxToRem(80);
  height: pxToRem(80);

  img {
    width: 100%;
    height: 100%;
  }
}

@include sm {
  .container {
    display: grid;
    grid-template-areas: '🐱 🐶';
    grid-template-columns: auto max-content;
    column-gap: pxToRem(32);
    padding: 0;
  }
  .main {
    grid-area: 🐱;
    max-width: 100%;
    align-self: center;
    padding: pxToRem(24);
  }

  .imgContainer {
    margin: 0;
    grid-area: 🐶;
    align-self: center;
    justify-content: center;
    gap: pxToRem(32);
    padding: pxToRem(12) pxToRem(24);
  }

  .image {
    width: pxToRem(90);
    height: pxToRem(90);
  }
}

@include md {
  .imgContainer {
    gap: pxToRem(40);
  }

  .image {
    width: pxToRem(120);
    height: pxToRem(120);
  }
}

@include lg {
  .container {
    grid-template-columns: auto max-content;
  }
}

@include xl {
  .container {
    padding: 0 pxToRem(40);
    max-width: 100%;
    gap: pxToRem(80);
  }

  .main {
    padding: pxToRem(48) 0;
  }

  .image {
    width: pxToRem(172);
    height: pxToRem(172);
  }
}
