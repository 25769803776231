@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: pxToRem(20);
  column-gap: pxToRem(20);
}

.tileWrapper {
  container-type: inline-size;
}

.tile {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: var(--cor_rad-lg);
  overflow: hidden;
  padding: pxToRem(16);
  box-sizing: border-box;
}

.tileImageRatioHolder {
  display: block;
  width: 100%;
  aspect-ratio: 2/1;
}

.tileImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: var(--for-neu-tie3);
}

.tileImageMobile {
  object-position: top center;
}
.tileImageDesktop {
  object-position: right center;
  display: none;
}

.tileContent {
  position: relative;
  background: var(--bac-tie2);
  padding: pxToRem(16) pxToRem(24) pxToRem(24 + 56 + 16);
  border-radius: var(--cor_rad-xs);
  flex: 1;
  min-height: pxToRem(248);

  &:before {
    content: '';
    background: inherit;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skewY(calc(-1 * var(--brand-angle)));
    transform-origin: top left;
    border-radius: inherit;
  }
}

.tileContentItem {
  position: relative;
}

.tileHeader {
  margin-bottom: pxToRem(8);

  .tileSubtitle {
    margin-top: pxToRem(8);
  }
}

.tileFooter {
  position: absolute;
  left: pxToRem(24);
  bottom: pxToRem(24);
}

@include md {
  .root:not(.root1Tile) {
    grid-template-columns: 1fr 1fr;
  }
}

// Styles for wide tile
@container (min-width: #{pxToRem(640)}) {
  .tileImageRatioHolder,
  .tileImageMobile {
    display: none;
  }

  .tileImageDesktop {
    display: block;
  }

  .tileContent {
    max-width: 50%;
    padding-top: pxToRem(24);
    padding-right: pxToRem(16);
    min-height: pxToRem(304);

    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: skewX(calc(-1 * var(--brand-angle)));
      transform-origin: bottom right;
    }
  }
}
