@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.contentWrapper {
  background: var(--bac-tie2);
  border-radius: var(--cor_rad-lg);
  overflow: hidden;
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: pxToRem(151);

  &:after {
    position: absolute;
    bottom: 0;
    height: pxToRem(10);
    width: 100%;
    background: linear-gradient(0deg, var(--bac-tie2) 0%, rgba(243, 241, 255, 0) 100%);
    content: '';
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;

  &.mobileImage {
    display: block;
  }

  &.tabletImage {
    display: none;
  }

  &.desktopImage {
    display: none;
  }
}

.content {
  padding: pxToRem(24) pxToRem(32) pxToRem(32);
}

.textWrapper {
  margin-bottom: pxToRem(16);
  text-align: center;
}

.title {
  margin: 0 0 pxToRem(8) 0;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);
}

.cta {
  width: 100%;
}

@include sm {
  .imageContainer {
    min-height: pxToRem(320);

    &:after {
      display: none;
    }
  }

  .image {
    &.mobileImage {
      display: none;
    }

    &.tabletImage {
      display: block;
    }
  }

  .contentWrapper {
    display: grid;
    grid-template-columns: 55% 45%;
  }

  .content {
    padding: pxToRem(40) pxToRem(32);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cta {
    width: pxToRem(148);
    margin: 0 auto;
  }
}

@include lg {
  .image {
    &.tabletImage {
      display: none;
    }

    &.desktopImage {
      display: block;
    }
  }

  .contentWrapper {
    grid-template-columns: 50% 50%;
  }
}
